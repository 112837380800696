import React, { useContext } from 'react';

import Link from '../Links';
import Icons from '../Icons';
import { AuthUserContext } from '../Session';

export default function HeaderAccount() {
  const authUser = useContext(AuthUserContext);
  const accountLink = authUser !== null ? 'My Account' : 'Login/Register';
  return (
    <Link
      to="/account/"
      label={accountLink}
      style={{ display: `flex`, alignItems: `center` }}
    >
      <Icons.User />
      <span className="accountLinkText" style={linkStyle}>
        {accountLink}
      </span>
    </Link>
  );
}

const linkStyle = {
  fontFamily: `'Corinthian Bold', sans-serif`,
  fontSize: `22px`,
  textTransform: `uppercase`,
  color: `#fff`,
  lineHeight: 1,
  marginLeft: `15px`,
};
